h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,{
	color:$navy;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight:700;
	letter-spacing:0.85px;
}

h1.p{
	font-size:1rem;
}

p, .no-slab, input, label, button{
	color:$gray-600;
	font-family:'Source Sans Pro', sans-serif;
	font-weight:300;
	&.big{
		font-size:20px;
	}
}

label, b, strong{
	font-weight:bold;
}

a{
	text-decoration:none;
	&:hover, &:focus{
		text-decoration:none;
	}	
}

.fw-light,
.fw-light p{
	font-weight:300!important;
}

.fw-normal,
.fw-normal p{
	font-weight:400!important;
}

.text-white{
	a{
		color: $white;
		&:hover, &:focus{
			color: darken($white, 15%);
		}
	}
}

.text-justify{
	text-align:justify;
}

@each $color, $value in $theme-colors {
  .text-#{$color} {
    color:$value;
  }
}

.text-link{
	color:#231f1c;
	opacity:0.6;
	border-bottom: 1px dotted #231f1c;
	transition:all .5s;
	&:hover, &:focus{
		border-bottom: 1.5px dotted #231f1c;
		opacity:1;
	}	
}

.mb--15{
	margin-bottom:-15px;
}

.mb--25{
	margin-bottom:-25px;
}

.mb--50{
	margin-bottom:-50px;
}

.mt--5{
	margin-top:-5px;
}

.mt--50{
	margin-top:-50px;
}

.ml--50{
	margin-left:-50px;
}


@each $color, $value in $colors {
  .text-#{$color} {
    color:$value;
  }
}

.btn{
	letter-spacing:2px;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    color:$white;
		&:hover, &:focus{
			border-color:darken($value,5%);
			background:darken($value,5%);
			color:$white;
		}
  }
}

.btn-white{
	background:$white;
	border-color:$white;
	color:$primary;
	&:hover, &:focus{
		background:darken($white,5%);
	}
}

.btn-close {
	background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-social{
	height: 50px;
	width: 50px;
}


@each $color, $value in $colors {
  .bg-#{$color} {
    background:$value;
  }
}

@each $color, $value in $colors {
  .bg-light-#{$color} {
    background:lighten($value,30);
  }
}

.form-control {
  background-color:$white!important;
  border:0px solid transparent;
  border-radius:10px!important;
  box-shadow:0 1rem 3rem rgba($black, 0.10);
  &::placeholder{
  	color:$gray-400;
  	font-weight:300;
  }
}

.icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	line-height: 1rem;
	min-width: 2rem;
	min-height: 2rem;
	padding: 0;
}

.custom-control-label::before {
	background-color: $gray-200;
	border: $gray-200 solid 1px;
}

.form-check-input {
	background-color:#DDF5FF;
	border-color:#DDF5FF;
	height:1.5rem;
}

.border-dotted-top{
	border-top: 2px dotted $gray-300;
}

.sub-header{
	min-height:40px;
	position:relative;
	z-index:2;
	a{
		color:$gray-400;
		&:hover,&:focus{
			color:$white;
		}
	}
}

.navbar-container{
	background:$navy;
}

.navbar-exintursa{
	position:relative;
	z-index:1;
	.navbar-brand{
		img{
			max-width:225px;
		}
	}
	.navbar-dark{
		.navbar-nav{
			.active{
				> .nav-link{
					font-weight: bold;
					&:hover,&:focus{
						font-weight: bold;
					}
				}
			}
			.nav-link{
				color:$white;
				font-size:1.2rem;
				font-weight:700;
				letter-spacing:1.5px;
				transition:all .5s;
				text-transform:uppercase;
				cursor:pointer;
				&:hover,&:focus{
					font-weight: bold;
				}
			}
			.nav-item{
				.btn-outline-light{
					&:hover,&:focus{
						color:$navy;
					}
				}
			}
		}
	}
	.dropdown{
		.submenu{
		  height: auto;
			opacity:0;
			transform: translateY(50px);
			.dropdown-menu{
				height:0px;
				border-radius:4px;
				display:block;
				left: auto;
				margin-top: 0.125rem;
				max-height:800px;
				opacity: 0;
				right: 0;
				top: 100%;
				transform: translateY(50px);
				transition: all 0.4s ease-out;
				.row, img{
					display:none;
				}
				img{
					border-radius:4px;
				}
				&:after {
			    content: '';
			    position: absolute;
			    display: block;
			    width: 0;
			    z-index: 1;
			    border-style: solid;
			    border-color: $white transparent;
			    border-width: 0 10px 10px;
			    top: -10px;
			    right: 70px;
			    margin-left: -10px;
			    opacity:0;
			    transition: all 0.4s ease-out;
				}
			}
		}
		&:hover > .submenu{
			display:block;
			height:auto;
			opacity:1;
			transform: translateY(0px);
			.dropdown-menu {
			  height: auto;
				opacity: 1;
				transform: translateY(0px);
				img{
					display:block;
				}
				.row{
					display:flex;
				}
				&:after {
					opacity:1;
				}
			}
		}
	}
}

.head{
		&.fixedHeader{
		animation: fixedHeader 1s ease-out;
		background:$blue;
		position:fixed;
		top:0;
		width:100%;
		z-index:999;
		.navbar{
			padding-top:.2rem;
			padding-bottom:.2rem;
		}
		.navbar-brand{
			padding-top:.1rem;
			img{
				max-height:45px;
			}
		}
		.head-menu{
			display:none;
		}
		.fixed-menu{
			display:flex;
		}
	}
	.head-menu{
		display:flex;
	}
	.fixed-menu{
		display:none;
	}
}

header {
  position: relative;
  height: 85vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
  top:-100px;
  z-index:0;
  img.bg {
  	position: absolute;
  	top: 40%;
  	left: 50%;
  	min-width: 100%;
  	min-height: 100%;
  	width: auto;
  	height: auto;
  	z-index: 0;
  	-ms-transform: translateX(-50%) translateY(-50%);
  	-moz-transform: translateX(-50%) translateY(-50%);
  	-webkit-transform: translateX(-50%) translateY(-50%);
  	transform: translateX(-50%) translateY(-50%);
  }
  .container{
  	height:calc(100% - 50px);
  	position: relative;
  	z-index: 3;
  }
  .title{
  	max-width:440px;
  	width:100%;
  }
  .btn{
  	min-width:175px;
  }
  &.hotel-header{
  	background-size:cover;
  	background-repeat:no-repeat;
  	background-position:bottom center;
  }
}
.services{
	background-image:url('../../img/bg-weather.png');
	background-size:1300px auto;
	background-repeat:repeat;
	min-height:50px;
	position: relative;
	z-index: 2;
	top:-100px;
	.row{
		min-height:50px;
	}
	p{
		letter-spacing:1px;
	}
}

.card-hotel{
	box-shadow:$box-shadow-lg;
	min-height: calc(100% - 50px);
	img{
		border-top-left-radius:$border-radius;
		border-top-right-radius:$border-radius;
	}
}

a .card-hotel{
	transition:all .5s;
	&:hover, &:focus{
		box-shadow:$box-shadow-sm;
		img{
			filter: grayscale(50%) brightness(85%);
		}
	}
}

.card-hotel,
.price-hotel{
	h6 small{
		font-size:14px;
		font-weight:normal;
	}
}

.card-room{
	.carousel-item-room{
		background-repeat:no-repeat;
		background-size:cover;
		border-radius:2rem 0 0 2rem; 
		min-height:100%;
	}
	.btn-primary{
		border-radius:0 0 2rem 0;
	}
	.card-body{
		min-height:320px;
	}
}

.services-hotel{
	img{
		display:block;
		margin:5px auto;
		max-width:48px;
	}
}

.section-weather{
	background-image:url('../../img/bg-weather.png');
	background-size:1300px auto;
	background-repeat:repeat;
	padding-left:65px;
	padding-right:65px;
	.card{
		svg{
			fill:#BCBCBC;
			height:20px;
			width:20px;
		}
		p{
			color:#BCBCBC;
		}
		&.this-month{
			background-color:$blue;
			p{
				color:$white!important;
			}
			svg{
				fill:$orange;
			}
		}
	}
}

.section-solar{
	background-image:url('../../img/solar.jpg');
	background-size:cover;
	background-repeat:no-repeat;
	min-height:500px;
}

.section-comments{	
	padding-left:65px;
	padding-right:65px;
}

.carousel-control-prev-icon{
	background-size:50%;
	&:hover, &:focus{
		background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
		background-repeat:no-repeat;
		background-size:50%;
		background-position: 50%;
	}
}
.carousel-control-next-icon{
	background-size:50%;
	&:hover, &:focus{
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
		background-repeat:no-repeat;
		background-size:50%;
		background-position: 50%;
	}
}

.footer{
	background-image:url('../../img/bg-weather.png');
	background-size:1300px auto;
	background-repeat:repeat;
	color:$gray-600;
	h6, p, a, .fas{
		color:$gray-600;
	}
	.footer-links{
		h6{
			font-size:18px;
			font-weight:300;
			letter-spacing:1px;
		}
		p a{
			font-size:18px;
			font-weight:700;
			letter-spacing:1px;
		}
	}
	.copy{
		border-top:1px solid $gray-600;
	}
}

#button-top {
  font-size: 1.8em;
  line-height: 50px;
  display: inline-block;
  background-color: rgba($gray-900,0.7);
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 2rem;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: background-color .3s, 
    opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
	&:hover, 
	&:active{
	  cursor: pointer;
	  background-color: $gray-900;
	}
	&.show {
	  opacity: 1;
	  visibility: visible;
	}
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
}

.marquee img {
  animation: marquee 4s linear infinite;
  display: inline-block;
  padding-right: 10px;
}

.flicker {
  animation: flicker 3s linear infinite;
  display: inline-block;
  padding-right: 10px;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to { 
    transform: translateX(-100%);
  }
}

@keyframes fixedHeader {
  from {
			transform: translateY(-150px);
  }
  to { 
			transform: translateY(0px);
  }
}

@keyframes flicker {
	0%   { opacity:1; }
	50%  { opacity:0.7; }
	100% { opacity:1; }
}

@include media-breakpoint-down(lg) {
.head{
	&.topHeader{
		position:relative;
		.sub-header,
		.navbar-container{
			position:relative;
		}
		.navbar-mobile{
			top:-140px;
		}
	}
	&.fixedHeader{
		position:relative;
		.sub-header,
		.navbar-container{
			position:relative;
		}
		.navbar-mobile{
			animation: fixedHeader 1.5s ease-out;
			position:fixed;
			top:0;
			width:100%;
			z-index:999;
		}
	}
}
	.navbar-brand{
		img{
			max-width:150px;
		}
	}
	.navbar-light{
		.navbar-nav{
			.nav-item{
				.nav-link{
					font-size:0.9rem;
					padding-left:0.9rem;
					padding-right:0.9rem;
				}
			}
		}
	} 
	.section-weather{
		padding-left:45px;
		padding-right:45px;
	}
	header {
	}
  .services{
  	img{
  		width:28px;
  	}
  	p{
  		letter-spacing:0.75px;
  		font-size:12px;
  	}
  }
}

@include media-breakpoint-down(md) {
	.navbar-exintursa .navbar-brand{
		img{
			max-width:150px;
		}
	}
	.navbar-light{
		.navbar-nav{
			.nav-item{
				border-top: 2px dotted $gray-300;
				.nav-link{
					font-size:1rem;
					padding:1rem 0;
				}
			}
		}
	}
	header {
		top:-150px;
	  .container{
	  	height:calc(100% - 75px);
	  }
	}
  .services{
  	min-height:75px;
  	top:-150px;
  	.row{
  		min-height:75px;
  	}
  	img{
  		width:24px;
  	}
  	p{
  		letter-spacing:0.5px;
  		font-size:12px;
  	}
  }
	.card-room{
		.carousel-item-room{
			border-radius:2rem 2rem 0 0;
			min-height:320px;
		}
		.btn-primary{
			border-radius:0 0 2rem 2rem;
		}
	}
}

@include media-breakpoint-down(sm) {
	.navbar-brand{
		img{
			max-width:100px;
		}
	}
	.head{
			.navbar-brand img{
				max-height:40px;
			}
			&.fixedHeader{
				.navbar-brand img{
					max-height:45px;
				}
		}
	}
	header {
		height: calc(100vh - 75px);
    min-height: 320px;
	  img {
	  	left:60%;
	  	min-height:inherit;
	  	min-width:inherit;
	  	height: 105%;
	  }
	  .title{
	  	max-width:320px;
	  	width:100%;
	  }
	  &.hotel-header{
	  	background-position:bottom right;
	  }
	}
  .services{
  	img{
  		width:22px;
  	}
  	p{
  		letter-spacing:0.5px;
  		font-size:11px;
  	}
  }
	#lightgallery{
		.photo-grid{
			height:175px;
		}
	}
}

@include media-breakpoint-down(xs) {

}





